<template>
  <v-menu
      open-on-hover
      bottom
      offset-y
      rounded
      transition="fab-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-avatar color="#7CB342" size="48" class="mx-5 elevation-3" v-bind="attrs" v-on="on">
        <v-icon class="light-blue accent-4 white--text">mdi-account-circle</v-icon>
      </v-avatar>
    </template>

    <v-list>
      <v-list-item>
        <v-list-item-title>
          <v-icon class="px-1">mdi-account-tie</v-icon>
          Username: {{ this.$store.state.user }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item>
        <v-list-item-title>
          <v-icon class="px-1">mdi-account-group</v-icon>
          Role: {{ this.$store.state.group }}
        </v-list-item-title>
      </v-list-item>
      <router-link :to="{name:'PasswordReset'}" style="text-decoration: none; color: inherit">
        <v-list-item>
          <v-list-item-title>
            <v-icon class="px-1">mdi-lock-reset</v-icon>
            Change Password
          </v-list-item-title>
        </v-list-item>
      </router-link>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "userFab"
}
</script>

<style scoped>

</style>