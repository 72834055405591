<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header
          v-if="office_menu_item"
          color="orange"
          expand-icon="mdi-bank-check"
          class="font-weight-bold elevation-9"
      >
        Offices
      </v-expansion-panel-header>
      <v-expansion-panel-content color="amber lighten-4"></v-expansion-panel-content>
      <v-expansion-panel-content v-if="circle_list_menu" color="amber lighten-4">
        <router-link :to="{name:'Circles'}" style="text-decoration: none; color: inherit">
          <v-btn
              color="orange"
              block
              small
              elevation="9"
              class="font-weight-bold"
          >

            <v-icon class="px-2">
              mdi-office-building
            </v-icon>
            Circle Office
          </v-btn>
        </router-link>
      </v-expansion-panel-content>
      <v-expansion-panel-content v-if="zone_list_menu" color="amber lighten-4">
        <router-link :to="{name:'Zones'}" style="text-decoration: none; color: inherit">
          <v-btn
              color="orange"
              block
              small
              elevation="9"
              class="font-weight-bold"
          >

            <v-icon class="px-2">
              mdi-office-building
            </v-icon>
            Zonal Office
          </v-btn>
        </router-link>
      </v-expansion-panel-content>
      <v-expansion-panel-content v-if="branch_list_menu" color="amber lighten-4">
        <router-link :to="{name:'Branches'}" style="text-decoration: none; color: inherit">
          <v-btn
              color="orange"
              block
              small
              elevation="9"
              class="font-weight-bold"
          >

            <v-icon class="px-2">
              mdi-office-building
            </v-icon>
            Branch Office
          </v-btn>
        </router-link>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: "OfficeMenu",
  computed: {
    office_menu_item() {
      return this.$store.getters.permissionCheckers('users.office_menu')
    },
    circle_list_menu() {
      return this.$store.getters.permissionCheckers('users.view_circleoffice')
    },
    zone_list_menu() {
      return this.$store.getters.permissionCheckers('users.view_zonaloffice')
    },
    branch_list_menu() {
      return this.$store.getters.permissionCheckers('users.view_branchoffice')
    }
  }
}
</script>

<style scoped>

</style>