import axios from "axios";
import period_urls from "@/constants/period_urls";
import store from "@/store/index";

const PeriodFunctions = {
  monthly: {
    last: get_last_period,
  },
};

function get_last_period() {
  axios.defaults.baseURL = store.state.baseURL;
  axios.defaults.headers.common[
    "Authorization"
  ] = `mamla ${store.state.accessToken}`;
  let config = {
    headers: {
      multi: "true",
    },
  };
  axios
    .get(period_urls.monthly.current, config)
    .then((resp) => {
      store.commit("setCurrentPeriod", resp.data.periods);
      store.commit("setJWT", resp.data.key);
      store.commit("update_snackbar", {
        text: "Period Value refreshed",
        status: true,
      });
    })
    .catch((error) => {
      store.commit("setJWT", error.response.data.key);
      store.commit("setCurrentPeriod", null);
      store.commit("update_snackbar", {
        text: "Current Period is not initialized",
        status: true,
      });
    });
}

export default PeriodFunctions;
