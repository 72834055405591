<template>
  <v-container>
    <v-app-bar app class="orange accent-2">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <router-link :to="{name:'Dashboard'}" style="text-decoration:none; color:inherit">
        <v-icon @click="AppBarIconHandler">mdi-bank</v-icon>
      </router-link>
      <v-toolbar-title class="white--text font-weight-bold px-2">
        Agrani Bank Legal Matter System
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn class="text-capitalize mx-2" @click="get_current_period" rounded :loading="loading">
        <v-icon class="px-2">mdi-calendar</v-icon>
        <span v-if="!this.$store.state.current_period">
          Not Initialized
        </span>
        <span v-else>
          Current Period: {{ this.$store.state.current_period.name }}
        </span>
      </v-btn>
      <v-btn class="text-capitalize" @click="extendingLogin" rounded :loading="loading">
        <v-icon class="px-2">mdi-alarm-plus</v-icon>
        Auto Logout {{ logoutTime }}<br/>
        Click to extend
      </v-btn>
      <user-fab/>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app class="dark lighten-3" color="amber accent-1" hide-overlay width="300">
      <v-expansion-panels>
        <AdministratorMenu></AdministratorMenu>
        <MamlaMenu></MamlaMenu>
        <ReportMenu/>
        <v-expansion-panel>
          <v-expansion-panel-header color="amber accent-1" expand-icon="mdi-logout" @click="handleLogout">
            Logout
          </v-expansion-panel-header>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-navigation-drawer>
  </v-container>
</template>

<script>

import AdministratorMenu from '@/components/appbar/administratormenu.vue'
import MamlaMenu from "@/components/appbar/mamla/mamla_menu";
import userFab from "@/components/userFab";
import moment from 'moment'
import axios from "axios";
import store from "@/store";
// import period_urls from "@/constants/period_urls";
import PeriodFunctons from "@/constants/PeriodFunctons";
import ReportMenu from "@/components/appbar/report/report_menu.vue";

export default {
  name: "AppBarComponent",
  data: () => ({
    drawer: false,
    logoutTime: null,
    loading: false,
    logout_counter: null,
    get_current_period: PeriodFunctons.monthly.last,
    worker: null
  }),
  components: {ReportMenu, AdministratorMenu, userFab, MamlaMenu},
  computed: {
    axios_conf: () => {
      axios.defaults.baseURL = store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${store.state.accessToken}`
      return 0
    },
  },
  methods: {
    loginButton() {
      this.$router.push({name: "Login"})
    },
    AppBarIconHandler() {
      this.$router.push({name: "Dashboard"})
    },
    logOutCounter() {
      let valid_til = moment.unix(this.$store.state.validTill)
      let server_time = moment.unix(this.$store.state.server_time)
      this.logoutTime = valid_til.from(server_time)
      if (typeof Worker !== "undefined") {
        if (this.worker === null) {
          this.worker = new Worker("./worker.js")
          this.worker.onmessage = (event) => {
            this.handleWorkerMessage(event.data);
          }
        }
        this.worker.postMessage(1);
      } else {
        let interval = setInterval(() => {
          this.$store.commit('increaseServerTime', 1)
          this.logoutTime = moment.unix(this.$store.state.validTill).from(moment.unix(this.$store.state.server_time))
          let time = this.$store.state.server_time
          if (time > this.$store.state.validTill) {
            this.$store.commit('setLogout')
            this.$router.replace({name: 'Login'})
            clearInterval(interval)
          }
        }, 1000)
        this.logout_counter = interval
      }
    },
    handleWorkerMessage(data) {
      // console.log("Getting Data from worker: ", data)
      this.$store.commit('increaseServerTime', data)
      this.logoutTime = moment.unix(this.$store.state.validTill).from(moment.unix(this.$store.state.server_time))
      let time = this.$store.state.server_time
      if (time > this.$store.state.validTill) {
        this.$store.commit('setLogout')
        this.worker.terminate()
        this.$router.replace({name: 'Login'})
      }
    },
    async extendingLogin() {
      this.axios_conf
      let data = {
        headers: {
          multi: true
        }
      }
      this.loading = true
      await axios.get('user/refresh_token', data)
          .then((resp) => {
            this.$store.commit('refreshToken', resp.data)
          }).catch((err) => {
            console.log(err)
            this.$store.commit('setLogout')
            if (this.worker !== null) {
              this.worker.terminate()
            }
            this.$router.replace({name: 'Login'})
          })
      this.loading = false
    },
    async handleLogout() {
      this.axios_conf
      await axios.post('user/logout')
      if (this.worker !== null) {
        this.worker.terminate()
      }
      this.$router.replace({name: 'Login'})
      this.$store.commit('setLogout')
    },
  },
  mounted() {
    this.logOutCounter();
  },
  beforeDestroy() {
    clearInterval(this.logout_counter)
    if (this.worker !== null) {
      this.worker.terminate()
    }
  }
}
</script>

<style scoped>

</style>