<template>
  <v-expansion-panel-content v-if="is_visible" :color="themes.golden['v-expansion-panel-content']">
    <router-link :to="{name:this.link}" style="text-decoration: none; color: inherit">
      <v-btn
          color="orange"
          block
          small
          elevation="9"
          class="font-weight-bold"
          :width="width"
      >
        <v-row align="center" justify="center">
          <v-col cols="2">
            <v-icon class="px-2">
              {{ this.icon }}
            </v-icon>
          </v-col>
          <v-col col="10">
            {{ this.link_text }}
          </v-col>
        </v-row>
      </v-btn>
    </router-link>
  </v-expansion-panel-content>
</template>

<style scoped>

</style>

<script>

import themes from "@/constants/theming";

export default {
  name: "SubRouterLink",
  props: ['perm', 'icon', 'link', 'link_text', "width"],
  computed: {
    is_visible() {
      return this.$store.getters.permissionCheckers(this.perm)
    }
  },
  data: () => ({
    themes: themes
  })
}

</script>