<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header
          v-if="app_setting_menu_item"
          color="orange"
          expand-icon="mdi-cogs"
          class="font-weight-bold elevation-9"
      >
        Application Settings
      </v-expansion-panel-header>
      <v-expansion-panel-content color="amber lighten-4"></v-expansion-panel-content>
      <v-expansion-panel-content v-if="cause_button" color="amber lighten-4">
        <router-link :to="{name:'Cause'}" style="text-decoration: none; color: inherit">
          <v-btn
              color="orange"
              block
              small
              elevation="9"
              class="font-weight-bold"
          >
            <v-icon class="px-2">
              mdi-table-sync
            </v-icon>
            Cause Dropdown
          </v-btn>
        </router-link>
      </v-expansion-panel-content>
      <v-expansion-panel-content v-if="district_button" color="amber lighten-4">
        <router-link :to="{name:'District'}" style="text-decoration: none; color: inherit">
          <v-btn
              color="orange"
              block
              small
              elevation="9"
              class="font-weight-bold"
          >
            <v-icon class="px-2">
              mdi-table-sync
            </v-icon>
            Districts Dropdown
          </v-btn>
        </router-link>
      </v-expansion-panel-content>
      <v-expansion-panel-content v-if="loan_type_button" color="amber lighten-4">
        <router-link :to="{name:'LoanType'}" style="text-decoration: none; color: inherit">
          <v-btn
              color="orange"
              block
              small
              elevation="9"
              class="font-weight-bold"
          >
            <v-icon class="px-2">
              mdi-table-sync
            </v-icon>
            Bank Loan Type
          </v-btn>
        </router-link>
      </v-expansion-panel-content>
      <v-expansion-panel-content v-if="court_type_button" color="amber lighten-4">
        <router-link :to="{name:'CourtType'}" style="text-decoration: none; color: inherit">
          <v-btn
              color="orange"
              block
              small
              elevation="9"
              class="font-weight-bold"
          >
            <v-icon class="px-2">
              mdi-table-sync
            </v-icon>
            Court Types
          </v-btn>
        </router-link>
      </v-expansion-panel-content>
      <v-expansion-panel-content v-if="court_button" color="amber lighten-4">
        <router-link :to="{name:'Court'}" style="text-decoration: none; color: inherit">
          <v-btn
              color="orange"
              block
              small
              elevation="9"
              class="font-weight-bold"
          >
            <v-icon class="px-2">
              mdi-table-sync
            </v-icon>
            Court Names
          </v-btn>
        </router-link>
      </v-expansion-panel-content>
      <v-expansion-panel-content v-if="case_type_button" color="amber lighten-4">
        <router-link :to="{name:'CaseType'}" style="text-decoration: none; color: inherit">
          <v-btn
              color="orange"
              block
              small
              elevation="9"
              class="font-weight-bold"
          >
            <v-icon class="px-2">
              mdi-table-sync
            </v-icon>
            Case Types
          </v-btn>
        </router-link>
      </v-expansion-panel-content>
      <v-expansion-panel-content v-if="case_filed_by_button" color="amber lighten-4">
        <router-link :to="{name:'CaseFiledBy'}" style="text-decoration: none; color: inherit">
          <v-btn
              color="orange"
              block
              small
              elevation="9"
              class="font-weight-bold"
          >
            <v-icon class="px-2">
              mdi-table-sync
            </v-icon>
            <span style="overflow-wrap: break-word">Case Filed By</span>
          </v-btn>
        </router-link>
      </v-expansion-panel-content>
      <v-expansion-panel-content v-if="disposal_process_button" color="amber lighten-4">
        <router-link :to="{name:'DisposalProcess'}" style="text-decoration: none; color: inherit">
          <v-btn
              color="orange"
              block
              small
              elevation="9"
              class="font-weight-bold"
          >
            <v-icon class="px-2">
              mdi-table-sync
            </v-icon>
            Disposal Process
          </v-btn>
        </router-link>
      </v-expansion-panel-content>
      <v-expansion-panel-content v-if="execution_type_button" color="amber lighten-4">
        <router-link :to="{name:'ExecutionType'}" style="text-decoration: none; color: inherit">
          <v-btn
              color="orange"
              block
              small
              elevation="9"
              class="font-weight-bold"
          >
            <v-icon class="px-2">
              mdi-table-sync
            </v-icon>
            Execution Types
          </v-btn>
        </router-link>
      </v-expansion-panel-content>
      <v-expansion-panel-content v-if="expense_button" color="amber lighten-4">
        <router-link :to="{name:'Expense'}" style="text-decoration: none; color: inherit">
          <v-btn
              color="orange"
              block
              small
              elevation="9"
              class="font-weight-bold"
          >
            <v-icon class="px-2">
              mdi-table-sync
            </v-icon>
            Expenses
          </v-btn>
        </router-link>
      </v-expansion-panel-content>
      <v-expansion-panel-content v-if="mode_of_recovery_button" color="amber lighten-4">
        <router-link :to="{name:'ModeOfRecovery'}" style="text-decoration: none; color: inherit">
          <v-btn
              color="orange"
              block
              small
              elevation="9"
              class="font-weight-bold"
          >
            <v-icon class="px-2">
              mdi-table-sync
            </v-icon>
            Mode Of Recovery
          </v-btn>
        </router-link>
      </v-expansion-panel-content>
      <v-expansion-panel-content v-if="nature_of_disposal_button" color="amber lighten-4">
        <router-link :to="{name:'NatureOfDisposal'}" style="text-decoration: none; color: inherit">
          <v-btn
              color="orange"
              block
              small
              elevation="9"
              class="font-weight-bold"
          >
            <v-icon class="px-2">
              mdi-table-sync
            </v-icon>
            Nature Of Disposal
          </v-btn>
        </router-link>
      </v-expansion-panel-content>
      <v-expansion-panel-content v-if="purpose_button" color="amber lighten-4">
        <router-link :to="{name:'Purpose'}" style="text-decoration: none; color: inherit">
          <v-btn
              color="orange"
              block
              small
              elevation="9"
              class="font-weight-bold"
          >
            <v-icon class="px-2">
              mdi-table-sync
            </v-icon>
            Purpose
          </v-btn>
        </router-link>
      </v-expansion-panel-content>
      <v-expansion-panel-content v-if="result_of_disposal_button" color="amber lighten-4">
        <router-link :to="{name:'ResultOfDisposal'}" style="text-decoration: none; color: inherit">
          <v-btn
              color="orange"
              block
              small
              elevation="9"
              class="font-weight-bold"
          >
            <v-icon class="px-2">
              mdi-table-sync
            </v-icon>
            Result of Disposal
          </v-btn>
        </router-link>
      </v-expansion-panel-content>
      <v-expansion-panel-content v-if="direction_bindiing_button" color="amber lighten-4">
        <router-link :to="{name:'DirectionBinding'}" style="text-decoration: none; color: inherit">
          <v-btn
              color="orange"
              block
              small
              elevation="9"
              class="font-weight-bold"
          >
            <v-icon class="px-2">
              mdi-table-sync
            </v-icon>
            Direction Binding
          </v-btn>
        </router-link>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: "AppSettingMenu",
  computed: {
    app_setting_menu_item() {
      return this.$store.getters.permissionCheckers('app_settings.app_setting_menu')
    },
    cause_button() {
      return this.$store.getters.permissionCheckers('app_settings.view_causes')
    },
    district_button() {
      return this.$store.getters.permissionCheckers('app_settings.view_districts')
    },
    loan_type_button() {
      return this.$store.getters.permissionCheckers('app_settings.view_loantypes')
    },
    court_type_button() {
      return this.$store.getters.permissionCheckers('app_settings.view_courttypes')
    },
    court_button() {
      return this.$store.getters.permissionCheckers('app_settings.view_courtnames')
    },
    case_type_button() {
      return this.$store.getters.permissionCheckers('app_settings.view_casetypes')
    },
    case_filed_by_button() {
      return this.$store.getters.permissionCheckers('app_settings.view_casefiledby')
    },
    disposal_process_button() {
      return this.$store.getters.permissionCheckers('app_settings.view_disposalprocess')
    },
    execution_type_button() {
      return this.$store.getters.permissionCheckers('app_settings.view_executiontypes')
    },
    expense_button() {
      return this.$store.getters.permissionCheckers('app_settings.view_expenses')
    },
    mode_of_recovery_button() {
      return this.$store.getters.permissionCheckers('app_settings.view_modeofrecovery')
    },
    nature_of_disposal_button() {
      return this.$store.getters.permissionCheckers('app_settings.view_natureofdisposal')
    },
    purpose_button() {
      return this.$store.getters.permissionCheckers('app_settings.view_purposes')
    },
    result_of_disposal_button() {
      return this.$store.getters.permissionCheckers('app_settings.view_resultofdisposal')
    },
    direction_bindiing_button() {
      return this.$store.getters.permissionCheckers('app_settings.view_directionbinding')
    },
  }
}
</script>

<style scoped>

</style>