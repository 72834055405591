<template>
  <span>
    <v-card
        elevation="5"
        class="rounded-10"
    >
      <v-card-title class="text-center"><span>{{ title }}</span></v-card-title>
      <v-divider class="mx-4"></v-divider>
      <v-card-text class="my-2">
        <v-skeleton-loader
            v-bind="attrs"
            type="card,card-text@3"
            v-if="!chartData"
            width="100%"
            height="100%"
        />
        <canvas v-if="chartData" :id=chart_id width="500%" height="400%"></canvas>
      </v-card-text>
    </v-card>
  </span>
</template>

<script>
import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import axios from "axios";

Chart.register(ChartDataLabels);

export default {
  name: "dashboard_chart",
  props: ['api_url', 'title', 'chart_id'],
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    }
  },
  data: () => ({
    chartData: null,
    chart_graph: null,
    attrs: {
      class: 'white',
      boilerplate: false,
      elevation: 2,
    },
  }),
  methods: {
    async get_data() {
      this.axios_conf
      let config = {
        headers: {
          multi: 'true'
        }
      }
      // console.log(this.api_url, this.title)
      await axios.get(this.api_url, config).then((resp) => {
        this.chartData = resp.data.chart_data
        this.$store.commit('setJWT', resp.data.key)
      })
    }
  },
  async mounted() {
    // console.log(this.chart_id)
    await this.get_data()
    const ctx = document.getElementById(this.chart_id);
    this.chart_graph = new Chart(ctx, this.chartData);
  },

}
</script>

<style scoped>

</style>