<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header
          v-if="personnel_menu_item"
          color="orange"
          expand-icon="mdi-account-tie"
          class="font-weight-bold elevation-9"
      >
        Personnel
      </v-expansion-panel-header>
      <v-expansion-panel-content color="amber lighten-4"></v-expansion-panel-content>
      <v-expansion-panel-content v-if="circle_list_menu" color="amber lighten-4">
        <router-link :to="{name:'Personnel'}" style="text-decoration: none; color: inherit">
          <v-btn
              color="orange"
              block
              small
              elevation="9"
              class="font-weight-bold"
          >

            <v-icon class="px-2">
              mdi-account-tie
            </v-icon>
            Personnel
          </v-btn>
        </router-link>
      </v-expansion-panel-content>
      <v-expansion-panel-content v-if="zone_list_menu" color="amber lighten-4">
        <router-link :to="{name:'Managers'}" style="text-decoration: none; color: inherit">
          <v-btn
              color="orange"
              block
              small
              elevation="9"
              class="font-weight-bold"
          >

            <v-icon class="px-2">
              mdi-badge-account
            </v-icon>
            Branch Managers
          </v-btn>
        </router-link>
      </v-expansion-panel-content>
      <v-expansion-panel-content v-if="zone_list_menu" color="amber lighten-4">
        <router-link :to="{name:'Lawyer'}" style="text-decoration: none; color: inherit">
          <v-btn
              color="orange"
              block
              small
              elevation="9"
              class="font-weight-bold"
          >
            <v-icon class="px-2">
              mdi-school
            </v-icon>
            Lawyers
          </v-btn>
        </router-link>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: "PersonnelMenu",
  computed: {
    personnel_menu_item() {
      return this.$store.getters.permissionCheckers('users.personnel_menu')
    },
    circle_list_menu() {
      return this.$store.getters.permissionCheckers('users.view_circleoffice')
    },
    zone_list_menu() {
      return this.$store.getters.permissionCheckers('users.view_zonaloffice')
    },
    branch_list_menu() {
      return this.$store.getters.permissionCheckers('users.view_branchoffice')
    }
  }
}
</script>

<style scoped>

</style>