<template>
  <v-card
      elevation="5"
      class="rounded-10"
  >
    <v-card-text class="my-2">
      <v-data-table
          :headers="column_headers"
          :items="table_items"
          sort-by="id"
          class="white"
          :loading="table_loading"
          :search="search"
          loading-text="Loading... Please wait"
          item-key="code"
          show-expand
          single-expand
          name="mamla_summary_table"
      >
        <template v-slot:top>
          <v-card-title
              flat
          >
            <v-card-title class="text-center"><span>{{ title }}</span></v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
          </v-card-title>
        </template>
        <template v-slot:item.claimed_amount="{item}">
          {{ util_func.currency_format.format(item.claimed_amount_branch) }}
        </template>
        <template v-slot:expanded-item="{ item, headers }">
          <td :colspan="headers.length">
            <mamla_summary_details :items="item.mamla_summary"/>
          </td>
        </template>

      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import mamla_summary_details from "@/components/dashboard/mamla_summary_details";
import parsing_functions from "@/constants/util_functions/parsing_functions";

export default {
  name: "mamla_summary_table",
  props: ["title", "api_url"],
  components: {
    mamla_summary_details
  },
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    }
  },
  data: () => ({
    reload_btn: false,
    table_loading: false,
    util_func: parsing_functions,
    column_headers: [
      {
        text: 'Branch Code',
        align: 'center',
        filterable: true,
        value: 'code',
      },
      {
        text: 'Branch Name',
        align: 'center',
        filterable: true,
        value: 'branch',
      },
      {
        text: 'Number of Mamla',
        align: 'center',
        filterable: true,
        value: 'mamla_num_branch',
      },
      {
        text: 'Claimed Amount',
        align: 'right',
        filterable: true,
        value: 'claimed_amount',
      },
      {
        text: '',
        align: 'center',
        filterable: false,
        value: 'data-table-expand',
      },
    ],
    table_items: [],
    search: '',
  }),
  methods: {
    async get_data() {
      this.axios_conf
      let config = {
        headers: {
          multi: 'true'
        }
      }
      // console.log(this.api_url, this.title)
      await axios.get(this.api_url, config).then((resp) => {
        this.table_items = resp.data.data
        this.$store.commit('setJWT', resp.data.key)
      })
    }
  },
  mounted() {
    this.get_data()
  }
}
</script>

<style scoped>

</style>