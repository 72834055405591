<template>
  <span>
    <v-row>
      <v-col cols="auto" v-if="mamla_live_branch">
        <dashboard_chart
            v-bind:api_url="this.dashboard_url.mamla_live_branch"
            chart_id="mamla_live_branch"
            :title="'Mamla (live) for ' + branch_data.name + ' branch'"
        />
      </v-col>
      <v-col cols="auto" v-if="mamla_live_ho">
        <dashboard_chart
            v-bind:api_url="this.dashboard_url.mamla_live_ho"
            chart_id="mamla_live_ho"
            title="Mamla (live) for all branch"
        />
      </v-col>
      <v-col cols="auto" v-if="mamla_live_zone">
        <dashboard_chart
            v-bind:api_url="this.dashboard_url.mamla_live_zone"
            chart_id="mamla_live_zone"
            :title="'Mamla (live) for ' + branch_data.zone +' zone'"
        />
      </v-col>
      <v-col cols="auto" v-if="mamla_live_circle">
        <dashboard_chart
            v-bind:api_url="this.dashboard_url.mamla_live_circle"
            chart_id="mamla_live_circle"
            :title="'Mamla (live) for ' + branch_data.circle +' circle'"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <mamla_summary_table :title="'Mamla (live) Summary of all branch'"
                             :api_url="this.dashboard_url.mamla_live_summary_ho"/>
      </v-col>
    </v-row>
    <div style="height: 500px; width: 100%; opacity: 100%; color: red" class="spacer"></div>
  </span>
</template>

<script>
import dashboard_urls from "@/constants/dashboard_urls";
import dashboard_chart from "@/components/dashboard/dashboard_chart";
import dashboard_perms from "@/constants/permissions/dashboard_perms";
import mamla_summary_table from "@/components/dashboard/mamla_summary_table";
import store from "@/store/index"

export default {
  name: "dashboard_chart_container",
  computed: {
    mamla_live_branch() {
      return this.$store.getters.permissionCheckers(dashboard_perms.mamla_live_branch)
    },
    mamla_live_ho() {
      return this.$store.getters.permissionCheckers(dashboard_perms.mamla_live_ho)
    },
    mamla_live_zone() {
      return this.$store.getters.permissionCheckers(dashboard_perms.mamla_live_zone)
    },
    mamla_live_circle() {
      return this.$store.getters.permissionCheckers(dashboard_perms.mamla_live_circle)
    },
    mamla_summary_table_all() {
      return this.$store.getters.permissionCheckers(dashboard_perms.mamla_live_circle)
    },
  },
  components: {
    dashboard_chart,
    mamla_summary_table
  },
  data: () => ({
    dashboard_url: dashboard_urls,
    branch_data: store.state.branch_data,
  }),
  methods: {},
  mounted() {
    // console.log(this.branch_data)
  }
}
</script>

<style scoped>

</style>