<template>
  <span class="my-3">
    <v-row justify="center">
      <v-col cols="4" class="text-left">
        <span class="font-weight-bold">Case Type</span>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="4" class="text-right">
        <span class="font-weight-bold">Count</span>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="4" class="text-right">
        <span class="font-weight-bold">Claimed Amount</span>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <span v-for="(item, index) in items" :key="index">
      <v-row justify="center">
        <v-col cols="4" class="text-left">
          <span class="font-weight-bold">{{ item.case_type }}</span>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="4" class="text-right">
          <span class="font-weight-bold">{{ item.count }}</span>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="4" class="text-right">
          <span class="font-weight-bold">{{ util_func.currency_format.format(item.claimed_amount) }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </span>
  </span>
</template>

<script>
import parsing_functions from "@/constants/util_functions/parsing_functions";

export default {
  name: "mamla_summary_details",
  props: ['items'],
  data: () => ({
    util_func: parsing_functions,
  })
}
</script>

<style scoped>

</style>