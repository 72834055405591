<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header
          v-if="panel_header_perm"
          color="orange"
          expand-icon="mdi-bank-check"
          class="font-weight-bold elevation-9"
      >
        Non Loan Related Suits
      </v-expansion-panel-header>
      <v-expansion-panel-content color="amber lighten-4"></v-expansion-panel-content>
      <sub_router_link
          v-if="add_file_menu"
          link="NlFileSubject"
          icon="mdi-file-plus"
          link_text="Add new File"
          :perm="file_perm_array.add"
      />
      <sub_router_link
          v-if="view_file_list"
          link="nlFileListOwnBranch"
          icon="mdi-file-plus"
          link_text="Own Branch Files"
          :perm="file_perm_array.list"
      />
      <sub_router_link
          v-if="data_list_menu"
          link="NLSSubject"
          icon="mdi-file-plus"
          link_text="Non Loan Subjects"
          :perm="data_perm_array.list"
      />
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>

import sub_router_link from "@/components/appbar/report/sub/sub_router_link.vue";
import nls_perm from "@/constants/permissions/nls_perms";
import nl_file_perm from "@/constants/permissions/nl_file_perms";


export default {
  name: "NlsMenu",
  components: {sub_router_link},
  computed: {
    panel_header_perm() {
      return this.$store.getters.permissionCheckers(nls_perm.subject.menu)
    },
    data_list_menu() {
      return this.$store.getters.permissionCheckers(nls_perm.subject.list)
    },
    data_perm_array() {
      return nls_perm.subject
    },
    file_perm_array() {
      return nl_file_perm.file
    },
    view_file_list() {
      return this.$store.getters.permissionCheckers(nl_file_perm.file.list)
    },
    add_file_menu() {
      return this.$store.getters.permissionCheckers(nl_file_perm.file.menu)
    }
  }
}
</script>

<style scoped>

</style>