<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header
          v-if="is_menu_visible"
          color="orange"
          :expand-icon="icon"
          class="font-weight-bold elevation-9"
      >
        {{ menu_text }}
      </v-expansion-panel-header>
      <v-expansion-panel-content color="amber lighten-4"></v-expansion-panel-content>
      <sub_router_link v-for="item in submenu_data_array"
                       :key="item.link"
                       :perm="item.perm"
                       :link="item.link"
                       :link_text="item.link_text"
                       :icon="item.icon"
      />
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<style scoped>

</style>
<script>
import sub_router_link from "@/components/appbar/report/sub/sub_router_link.vue";

export default {
  props: ['perm', 'submenu_data', 'icon', 'menu_text'],
  components: {sub_router_link},
  computed: {
    is_menu_visible() {
      return this.$store.getters.permissionCheckers(this.perm)
    },
    submenu_data_array() {
      return this.submenu_data
    }
  }
}

</script>