<template>
  <v-expansion-panel v-if="mamla_menu_item">
    <v-expansion-panel-header
        :color="themes.golden['v-expansion-panel-header']"
        expand-icon="mdi-file-document"
        class="font-weight-bold">
      Suits
    </v-expansion-panel-header>
    <v-expansion-panel-content :color="themes.golden['v-expansion-panel-content']">
      <LoanMamlaMenu></LoanMamlaMenu>
    </v-expansion-panel-content>
    <v-expansion-panel-content :color="themes.golden['v-expansion-panel-content']">
      <NlsMenu></NlsMenu>
    </v-expansion-panel-content>
    <v-expansion-panel-content :color="themes.golden['v-expansion-panel-content']">
      <sub_router_link
          v-if="manual_cib"
          link="BorrowerWithoutCib"
          icon="mdi-file-plus"
          link_text="BORROWER WITHOUT CIB"
          :perm="manual_cib_perm_array.view"
          width="210"
      />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>

import LoanMamlaMenu from "@/components/appbar/mamla/loan_mamla_menu"
import themes from "@/constants/theming";
import sub_router_link from "@/components/appbar/report/sub/sub_router_link.vue";
import cib_perms from "@/constants/permissions/cib_perms";
import NlsMenu from "@/components/appbar/mamla/NlsMenu.vue";

export default {
  name: "MamlaMenu",
  components: {sub_router_link, LoanMamlaMenu, NlsMenu},
  data: () => ({
    themes: themes,
    manual_cib_perm_array: cib_perms.manual
  }),
  computed: {
    mamla_menu_item() {
      return this.$store.getters.permissionCheckers('mamla_container.mamla_menu')
    },
    manual_cib() {
      return this.$store.getters.permissionCheckers(cib_perms.manual.view)
    }
  },
  methods: {},
  mounted() {
    console.log(this.$store.state.permissions)
  },
}
</script>

<style scoped>

</style>