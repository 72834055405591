<template>
  <v-expansion-panel>
    <v-expansion-panel-header
        v-if="report_main"
        color="amber accent-1"
        expand-icon="mdi-chart-multiple"
        class="font-weight-bold">
      Reports
    </v-expansion-panel-header>
    <v-expansion-panel-content color="amber lighten-4"></v-expansion-panel-content>
    <v-expansion-panel-content color="amber lighten-4">
      <sub_menu :perm="perms.view_report_all_branch_menu" icon="mdi-chart-box-outline" menu_text="All Branch Report"
                :submenu_data="all_branch_submenu_data"/>
    </v-expansion-panel-content>
    <v-expansion-panel-content color="amber lighten-4">
      <sub_menu :perm="perms.view_report_all_zone_menu" icon="mdi-chart-box-outline" menu_text="All Zone Report"
                :submenu_data="all_zone_submenu_data"/>
    </v-expansion-panel-content>
    <v-expansion-panel-content color="amber lighten-4">
      <sub_menu :perm="perms.view_report_all_circle_menu" icon="mdi-chart-box-outline" menu_text="All Circle Report"
                :submenu_data="all_circle_submenu_data"/>
    </v-expansion-panel-content>
    <v-expansion-panel-content color="amber lighten-4">
      <sub_menu :perm="perms.view_report_head_office_menu" icon="mdi-chart-box-outline" menu_text="Head Office Report"
                :submenu_data="head_office_submenu_data"/>
    </v-expansion-panel-content>
    <v-expansion-panel-content color="amber lighten-4">
      <sub_menu :perm="perms.view_report_own_branch_menu" icon="mdi-chart-box-outline" menu_text="Own Branch Report"
                :submenu_data="own_branch_submenu_data"/>
    </v-expansion-panel-content>
    <v-expansion-panel-content color="amber lighten-4">
      <sub_menu :perm="perms.view_report_own_zone_menu" icon="mdi-chart-box-outline" menu_text="Own Zone Report"
                :submenu_data="own_zone_submenu_data"/>
    </v-expansion-panel-content>
    <v-expansion-panel-content color="amber lighten-4">
      <sub_menu :perm="perms.view_report_own_circle_menu" icon="mdi-chart-box-outline" menu_text="Own Circle Report"
                :submenu_data="own_circle_submenu_data"/>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<style scoped>

</style>
<script>
import report_perms from "@/constants/permissions/report_permissions";
// import sub_router_link from "@/components/appbar/report/sub/sub_router_link.vue";
import sub_menu from "@/components/appbar/report/sub/sub_menu.vue";

export default {
  name: "ReportMenu",
  components: {
    sub_menu
  },
  data: () => ({
    all_branch_submenu_data_array: [
      {
        name: "all_branch_day_wise",
        perm: report_perms.view_report_branch_day_wise,
        icon: "mdi-chart-box-outline",
        link: "all_branch_day_wise",
        link_text: "Day Wise"
      },
      {
        name: "all_branch_non_day_wise",
        perm: report_perms.view_report_branch_non_day_wise,
        icon: "mdi-chart-box-outline",
        link: "all_branch_non_day_wise",
        link_text: "All Pending"
      }
    ],
    all_zone_submenu_data_array: [
      {
        name: "all_zone_day_wise",
        perm: report_perms.view_report_zone_day_wise,
        icon: "mdi-chart-box-outline",
        link: "all_zone_day_wise",
        link_text: "Day Wise"
      },
      {
        name: "all_zone_non_day_wise",
        perm: report_perms.view_report_zone_non_day_wise,
        icon: "mdi-chart-box-outline",
        link: "all_zone_non_day_wise",
        link_text: "All Pending"
      }
    ],
    all_circle_submenu_data_array: [
      {
        name: "all_circle_day_wise",
        perm: report_perms.view_report_circle_day_wise,
        icon: "mdi-chart-box-outline",
        link: "all_circle_day_wise",
        link_text: "Day Wise"
      },
      {
        name: "all_zone_non_day_wise",
        perm: report_perms.view_report_circle_non_day_wise,
        icon: "mdi-chart-box-outline",
        link: "all_circle_non_day_wise",
        link_text: "All Pending"
      }
    ],
    head_office_submenu_data_array: [
      {
        name: "head_office_day_wise",
        perm: report_perms.view_report_head_office_day_wise,
        icon: "mdi-chart-box-outline",
        link: "head_office_day_wise",
        link_text: "Day Wise"
      },
      {
        name: "head_office_non_day_wise",
        perm: report_perms.view_report_head_office_non_day_wise,
        icon: "mdi-chart-box-outline",
        link: "head_office_non_day_wise",
        link_text: "All Pending"
      }
    ],
    own_branch_submenu_data_array: [
      {
        name: "own_branch_day_wise",
        perm: report_perms.view_report_own_branch_day_wise,
        icon: "mdi-chart-box-outline",
        link: "own_branch_day_wise",
        link_text: "Day Wise"
      },
      {
        name: "own_branch_non_day_wise",
        perm: report_perms.view_report_own_branch_non_day_wise,
        icon: "mdi-chart-box-outline",
        link: "own_branch_non_day_wise",
        link_text: "All Pending"
      }
    ],
    own_zone_submenu_data_array: [
      {
        name: "own_zone_day_wise",
        perm: report_perms.view_report_own_zone_day_wise,
        icon: "mdi-chart-box-outline",
        link: "own_zone_day_wise",
        link_text: "Day Wise"
      },
      {
        name: "own_zone_non_day_wise",
        perm: report_perms.view_report_own_zone_non_day_wise,
        icon: "mdi-chart-box-outline",
        link: "own_zone_non_day_wise",
        link_text: "All Pending"
      }
    ],
    own_circle_submenu_data_array: [
      {
        name: "own_circle_day_wise",
        perm: report_perms.view_report_own_circle_day_wise,
        icon: "mdi-chart-box-outline",
        link: "own_circle_day_wise",
        link_text: "Day Wise"
      },
      {
        name: "own_circle_non_day_wise",
        perm: report_perms.view_report_own_circle_non_day_wise,
        icon: "mdi-chart-box-outline",
        link: "own_circle_non_day_wise",
        link_text: "All Pending"
      }
    ]
  }),
  computed: {
    report_main() {
      return this.$store.getters.permissionCheckers(report_perms.view_report_menu)
    },
    all_branch_submenu_data() {
      return this.all_branch_submenu_data_array
    },
    all_zone_submenu_data() {
      return this.all_zone_submenu_data_array
    },
    all_circle_submenu_data() {
      return this.all_circle_submenu_data_array
    },
    head_office_submenu_data() {
      return this.head_office_submenu_data_array
    },
    own_branch_submenu_data() {
      return this.own_branch_submenu_data_array
    },
    own_zone_submenu_data() {
      return this.own_zone_submenu_data_array
    },
    own_circle_submenu_data() {
      return this.own_circle_submenu_data_array
    },
    perms() {
      return report_perms
    }
  }
}

</script>