<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header
          v-if="cib_menu_item"
          color="orange"
          expand-icon="mdi-database-cog"
          class="font-weight-bold elevation-9"
      >
        CIB Data
      </v-expansion-panel-header>
      <v-expansion-panel-content color="amber lighten-4"></v-expansion-panel-content>
      <v-expansion-panel-content v-if="cib_loan_types" color="amber lighten-4">
        <router-link :to="{name:'LoanTypes'}" style="text-decoration: none; color: inherit">
          <v-btn
              color="orange"
              block
              small
              elevation="9"
              class="font-weight-bold"
          >
            <v-icon class="px-2">
              mdi-bank-transfer
            </v-icon>
            Loan Types
          </v-btn>
        </router-link>
      </v-expansion-panel-content>
      <v-expansion-panel-content v-if="cib_owner_types" color="amber lighten-4">
        <router-link :to="{name:'OwnerTypes'}" style="text-decoration: none; color: inherit">
          <v-btn
              color="orange"
              block
              small
              elevation="9"
              class="font-weight-bold"
          >
            <v-icon class="px-2">
              mdi-account-settings
            </v-icon>
            Owner Types
          </v-btn>
        </router-link>
      </v-expansion-panel-content>
      <v-expansion-panel-content v-if="cib_subjects" color="amber lighten-4">
        <router-link :to="{name:'cib_subjects'}" style="text-decoration: none; color: inherit">
          <v-btn
              color="orange"
              block
              small
              elevation="9"
              class="font-weight-bold"
          >
            <v-icon class="px-2">
              mdi-cog-sync
            </v-icon>
            CIB Subjects
          </v-btn>
        </router-link>
      </v-expansion-panel-content>
      <v-expansion-panel-content v-if="loan_data_types" color="amber lighten-4">
        <router-link :to="{name:'LoanData'}" style="text-decoration: none; color: inherit">
          <v-btn
              color="orange"
              block
              small
              elevation="9"
              class="font-weight-bold"
          >
            <v-icon class="px-2">
              mdi-cog-sync
            </v-icon>
            Loan Data
          </v-btn>
        </router-link>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: "CIBMenu",
  computed: {
    cib_menu_item() {
      return this.$store.getters.permissionCheckers('cib.cib_menu')
    },
    cib_subjects() {
      return this.$store.getters.permissionCheckers('cib.view_cibsubjects')
    },
    cib_loan_types() {
      return this.$store.getters.permissionCheckers('cib.view_loantypes')
    },
    cib_owner_types() {
      return this.$store.getters.permissionCheckers('cib.view_ownertypes')
    },
    loan_data_types() {
      return this.$store.getters.permissionCheckers('cib.view_loandata')
    },
  }
}
</script>

<style scoped>

</style>