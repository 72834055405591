<template>
  <v-expansion-panel>
    <v-expansion-panel-header
        v-if="admin_menu_item"
        color="amber accent-1"
        expand-icon="mdi-police-badge"
        class="font-weight-bold">
      Administration
    </v-expansion-panel-header>
    <v-expansion-panel-content v-if="viewUserList" color="amber lighten-4">
      <router-link :to="{name:'UserList'}" style="text-decoration: none; color: inherit">
        <v-btn
            color="orange"
            block
            small
            elevation="9"
            class="font-weight-bold"
        >

          <v-icon class="px-2">
            mdi-account-multiple
          </v-icon>
          User List
        </v-btn>
      </router-link>
    </v-expansion-panel-content>
    <v-expansion-panel-content v-if="view_period" color="amber lighten-4">
      <router-link :to="{name:'Yearly'}" style="text-decoration: none; color: inherit">
        <v-btn
            color="orange"
            block
            small
            elevation="9"
            class="font-weight-bold"
        >

          <v-icon class="px-2">
            mdi-timeline
          </v-icon>
          Periods
        </v-btn>
      </router-link>
    </v-expansion-panel-content>
    <v-expansion-panel-content color="amber lighten-4">
      <office-menu></office-menu>
    </v-expansion-panel-content>
    <v-expansion-panel-content color="amber lighten-4">
      <personnel-menu></personnel-menu>
    </v-expansion-panel-content>
    <v-expansion-panel-content color="amber lighten-4">
      <c-i-b-menu/>
    </v-expansion-panel-content>
    <v-expansion-panel-content v-if="view_bg_tasks" color="amber lighten-4">
      <router-link :to="{name:'bg_tasks'}" style="text-decoration: none; color: inherit">
        <v-btn
            color="orange"
            block
            small
            elevation="9"
            class="font-weight-bold"
        >

          <v-icon class="px-2">
            mdi-cog-transfer
          </v-icon>
          Background Tasks
        </v-btn>
      </router-link>
    </v-expansion-panel-content>
    <v-expansion-panel-content color="amber lighten-4">
      <AppSettingMenu></AppSettingMenu>
    </v-expansion-panel-content>
    <!--    <v-expansion-panel-content v-if="viewUserList" color="amber lighten-4">-->
    <!--      <router-link :to="{name:'Managers'}" style="text-decoration: none; color: inherit">-->
    <!--        <v-btn-->
    <!--            color="orange"-->
    <!--            block-->
    <!--            small-->
    <!--            elevation="9"-->
    <!--            class="font-weight-bold"-->
    <!--        >-->

    <!--          <v-icon class="px-2">-->
    <!--            mdi-human-greeting-variant-->
    <!--          </v-icon>-->
    <!--          Manager-->
    <!--        </v-btn>-->
    <!--      </router-link>-->
    <!--    </v-expansion-panel-content>-->
  </v-expansion-panel>
</template>

<script>
import OfficeMenu from '@/components/appbar/officemenu'
import PersonnelMenu from '@/components/appbar/personnelmenu'
import CIBMenu from "@/components/appbar/CIB";
import AppSettingMenu from "@/components/appbar/app_setting/AppSettingMenu";

export default {
  name: "AdministratorMenu",
  components: {OfficeMenu, PersonnelMenu, CIBMenu, AppSettingMenu},
  computed: {
    admin_menu_item() {
      return this.$store.getters.permissionCheckers('users.administration_menu')
    },
    viewUserList() {
      return this.$store.getters.permissionCheckers('users.view_customuser')
    },
    addUser() {
      return this.$store.getters.permissionCheckers('users.add_customuser')
    },
    view_period() {
      return this.$store.getters.permissionCheckers('periods.view_yearlyperiods')
    },
    view_bg_tasks() {
      return this.$store.getters.permissionCheckers('running_tasks.view_runningtasks')
    },
    // managerList() {
    //   return this.$store.getters.permissionCheckers('users.branchmanagers')
    // }
  },
  methods: {},
  mounted() {
    console.log(this.$store.state.permissions)
  },
}
</script>

<style scoped>

</style>