<template>
  <v-container>
    <AppBarComponent></AppBarComponent>
    <div class="my-5">
      <v-snackbar
          :timeout="5000"
          v-model="snackbar_msg"
          absolute
          centered
          shaped
          top
          color="success"
      >
        <span style="overflow-wrap: break-word">{{ $store.state.snackbar_msg.text }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="$store.commit('update_snackbar', {text: null, status: false})"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
    <transition name="fade" mode="out-in" enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="animate__animated animate__fadeOut">
      <span>
        <dashboard_chart_container v-if="this.$route.name === 'Dashboard'"></dashboard_chart_container>
        <router-view></router-view>
      </span>
    </transition>
  </v-container>
</template>

<script>

import AppBarComponent from "@/components/appbar/appBarComponent.vue"
import dashboard_chart_container from "@/components/dashboard/dashboard_chart_container";

export default {
  name: 'Dashboard',
  data: () => ({}),
  components: {
    AppBarComponent,
    dashboard_chart_container
  },
  computed: {
    snackbar_msg: {
      get() {
        return this.$store.state.snackbar_msg.show
      },
      set(value) {
        this.$store.commit('update_snackbar', {text: null, status: value})
      }
    }
  },
  methods: {
    forcePassReset() {
      if (this.$store.state.passreset) {
        this.$router.replace({name: "PasswordReset"})
      }
    }
  },
  mounted() {
    document.title = 'Agrani Bank Legal Suit System | DashBoard';
    this.forcePassReset();
  }
}
</script>