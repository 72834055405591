<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header
          v-if="loan_mamla_menu_item"
          color="orange"
          expand-icon="mdi-bank-check"
          class="font-weight-bold elevation-9"
      >
        Loan Related Suits
      </v-expansion-panel-header>
      <v-expansion-panel-content color="amber lighten-4"></v-expansion-panel-content>
      <sub_router_link
          v-if="add_loan_mamla_menu"
          link="AddLoanMamla"
          icon="mdi-file-plus"
          link_text="Add New File"
          :perm="loan_mamla_file_perm_array.add"
      />
      <sub_router_link
          v-if="mamla_list_menu"
          :perm="loan_mamla_file_perm_array.list"
          icon="mdi-file-multiple"
          link_text="Own Branch File List"
          link="LoanMamlaList"
      />
      <sub_router_link
          v-if="zone_wise_list_menu"
          :perm="loan_mamla_file_perm_array.zone_wise_list"
          icon="mdi-file-multiple"
          link_text="Any Zone File List"
          link="LoanFileListZoneWise"
      />
      <sub_router_link
          v-if="circle_wise_list_menu"
          :perm="loan_mamla_file_perm_array.circle_wise_list"
          icon="mdi-file-multiple"
          link_text="Any Circle File List"
          link="LoanFileListCircleWise"
      />
      <sub_router_link
          v-if="own_zone_list_menu"
          :perm="loan_mamla_file_perm_array.own_zone_list"
          icon="mdi-file-multiple"
          link_text="Own Zone File List"
          link="LoanFileListOwnZone"
      />
      <sub_router_link
          v-if="own_circle_list_menu"
          :perm="loan_mamla_file_perm_array.own_circle_list"
          icon="mdi-file-multiple"
          link_text="Own Circle File List"
          link="LoanFileListOwnCircle"
      />
      <sub_router_link
          v-if="all_file_list_menu"
          :perm="loan_mamla_file_perm_array.own_circle_list"
          icon="mdi-file-multiple"
          link_text="All File List"
          link="AllFileList"
      />
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>

import sub_router_link from "@/components/appbar/report/sub/sub_router_link.vue";
import loan_mamla_file_perms from "@/constants/permissions/loan_mamla_file_perms";

export default {
  name: "LoanMamlaMenu",
  components: {sub_router_link},
  computed: {
    loan_mamla_menu_item() {
      return this.$store.getters.permissionCheckers(loan_mamla_file_perms.menu)
    },
    add_loan_mamla_menu() {
      return this.$store.getters.permissionCheckers(loan_mamla_file_perms.add)
    },
    mamla_list_menu() {
      return this.$store.getters.permissionCheckers(loan_mamla_file_perms.list)
    },
    zone_wise_list_menu() {
      return this.$store.getters.permissionCheckers(loan_mamla_file_perms.zone_wise_list)
    },
    circle_wise_list_menu() {
      return this.$store.getters.permissionCheckers(loan_mamla_file_perms.circle_wise_list)
    },
    own_circle_list_menu() {
      return this.$store.getters.permissionCheckers(loan_mamla_file_perms.own_circle_list)
    },
    own_zone_list_menu() {
      return this.$store.getters.permissionCheckers(loan_mamla_file_perms.own_zone_list)
    },
    all_file_list_menu() {
      return this.$store.getters.permissionCheckers(loan_mamla_file_perms.all_file_list)
    },
    loan_mamla_file_perm_array() {
      return loan_mamla_file_perms
    }
  }
}
</script>

<style scoped>

</style>